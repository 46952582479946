import { user_anonymous } from '~/lib/auth';
import { ActionFunctionArgs, LoaderFunctionArgs, redirect } from '@remix-run/cloudflare';
import { session_load, session_save } from '~/lib/session';
import { NavLink, useActionData, useLoaderData } from '@remix-run/react';
import { db_user_by_email, db_user_create, db_user_entity } from '~/.server/drizzle/repository/user';
import z from 'zod';
import { zodTEmailRequired, zodTPhone } from '~/lib/zod';
import ZodForm from '~/components/zod/ZodForm';
import ZodSubmitButton from '~/components/zod/ZodSubmitButton';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import FormGroup from '~/components/form/FormGroup';
import Heading from '~/components/layout/Heading';
import Link from '~/components/interactive/Link';
import IconGoogle from '~/components/icons/IconGoogle';
import { google_oauth_authorize_callback, google_oauth_authorize_url } from '~/.server/vendor/google';
import { cf_build_url } from '~/lib/cloudflare';
import NavigationButton from '~/components/interactive/NavigationButton';
import { json } from '@remix-run/cloudflare';
import { v4 } from 'uuid';
import Message from '~/components/Message';
import { remix_schema_post } from '~common/.server/remix';

const schema = z.object({
  email: zodTEmailRequired(),
  password: z.string(),
});

export async function loader(args: LoaderFunctionArgs) {
  await user_anonymous(args);

  const url = new URL(args.request.url);

  const redirected = cf_build_url(args.request, '/login');
  const token = url.searchParams.get('token');
  const userId = url.searchParams.get('user');

  let error: string | undefined;

  if (token && userId) {
    const user = await db_user_entity(args, userId);

    if (user.verifyUrlToken(url) && !user.disabled) {
      throw redirect(url.pathname, {
        headers: session_save(args, { user_id: userId }),
      });
    }

    error = 'The link you clicked has expired, please login with your credentials.';
  }

  const session = await session_load(args);

  if (url.searchParams.has('code')) {
    if (session?.verifier) {
      const callback = await google_oauth_authorize_callback(args, args.request.url, session.verifier, redirected);

      const email = zodTEmailRequired().safeParse(callback.user.email);

      if (email.success) {
        const user = await db_user_by_email(args, email.data);

        if (user) {
          if (!user.verified) {
            await user.db_verify();
          }

          if (!user.disabled) {
            throw redirect(session.redirect ?? '/', {
              headers: session_save(args, { user_id: user.id }),
            });
          }
        } else {
          const id = v4();

          await db_user_create({
            id,
            ctx: args,
            email: email.data,
            admin: email.data.includes('@p2s.io'),
            data: {
              google: true,
              password_change: true,
              first: callback.user.given_name,
              last: callback.user.family_name,
              accepted: new Date().toISOString(),
              verified: true,
            },
          });

          throw redirect(session.redirect ?? '/', {
            headers: session_save(args, { user_id: id }),
          });
        }
      }
    }
  }

  const env = args.context.env;
  if (!env?.GOOGLE_SECRET) {
    // If GOOGLE_SECRET is missing, we won't provide an OAuth URL
    return json(
      { google: null, error },
      { headers: session_save(args, { user_id: null, verifier: undefined, redirect: session?.redirect }) },
    );
  }

  const google = await google_oauth_authorize_url(args, redirected);

  return json(
    { google: google.url, error },
    {
      headers: session_save(args, { user_id: null, verifier: google.codeVerifier, redirect: session?.redirect }),
    },
  );
}

export async function action(args: ActionFunctionArgs) {
  const { email, password } = await remix_schema_post(args, schema);

  const user = await db_user_by_email(args, email);

  if (!user) {
    // not best practice but causes support issues
    return { error: 'That email does not exist in our system.' };
  }

  if (!user.verified) {
    return { error: 'Please verify your email address before logging in. Check your email for the verification link.' };
  }

  if (user.disabled) {
    return { error: 'Your account has been disabled.' };
  }

  if (user?.comparePassword(password)) {
    const session = await session_load(args);

    throw redirect(session?.redirect ?? '/', {
      headers: session_save(args, { user_id: user.id }),
    });
  }

  return { error: 'The password you provided is invalid.' };
}

export default function () {
  const response = useActionData<typeof action>();
  const { google, error } = useLoaderData<typeof loader>();

  return (
    <>
      {(response?.error ?? error) && (
        <div className="mb-6">
          <Message type="error">{response?.error ?? error}</Message>
        </div>
      )}
      <div className="pb-6 max-sm:hidden text-left">
        <Heading title="Welcome" size="xl" />
      </div>
      <ZodForm schema={schema}>
        <FormGroup>
          <ZodFieldInput name="email" placeholder="Email Address" autoFocus />
          <ZodFieldInput name="password" type="password" placeholder="Password" />
          <div className="flex items-center justify-between">
            <NavLink to="/forgot" className="text-xs font-medium">
              Forgot password?
            </NavLink>
            <ZodSubmitButton variant="tenant">Log In</ZodSubmitButton>
          </div>
        </FormGroup>
      </ZodForm>
      {google && (
        <div className="flex flex-col items-center border-t border-theme-separator mt-6 pt-4 text-xs">
          <div className="font-semibold mb-2">Or continue with</div>
          <NavigationButton to={google} icon={<IconGoogle />} variant="outline" popup={false}>
            Google
          </NavigationButton>
        </div>
      )}
      <div className="flex flex-col items-center border-t border-theme-separator mt-4 pt-4 text-xs font-semibold">
        <div>
          Don't have an account? <Link to="/register">Sign up here</Link>
        </div>
      </div>
    </>
  );
}
